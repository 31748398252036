import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, from, Observable} from "rxjs";
import {JSONWebToken} from "../entities/JSONWebToken";
import {User} from "../entities/User";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingController} from "@ionic/angular";
import {Plugins} from "protractor/built/plugins";

import {map, take, tap} from "rxjs/operators";
import {baseUrl} from "../parameters";
import {SerializationManager} from './SerializationManager';
import {Storage} from "@capacitor/storage";
import {storageService} from "./storage.service";
import {StreamChat} from "stream-chat";
import {StreamService} from "./stream.service";
import {TranslateService} from "@ngx-translate/core";
import {MessagingService} from "./messaging";
import {FcmService} from "../services/fcm.service";

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  loadingElement: HTMLIonLoadingElement;
  jwt = new BehaviorSubject<JSONWebToken>(null);
  user = new BehaviorSubject<User>(null);
  onLoginEvent = new BehaviorSubject<boolean>(null);
  connectedStreamClient: StreamChat;

  constructor(private translateService: TranslateService, private fmService: FcmService, private route: ActivatedRoute, private http: HttpClient, private stream: StreamService, private router: Router, private loadingController: LoadingController, private storageService: storageService, private messagingService: MessagingService) {
  }

  //set jwt and user observable values
  userDataAutoSetting(user: User, tokenObject: JSONWebToken) {
    if (tokenObject.expiration_date <= new Date()) {
      this.logout('/connexion');
      return false;
    }
    this.user.next(user);
    this.jwt.next(tokenObject);

    this.fmService.initPush();
   if (user.organisation)
     this.getChatUser(user);
  }

  getUserAsObservable() {
    return this.user.asObservable()
  }

  //autologin a user
  autoLogin() {
    // this.messagingService.requestPermission();
    return forkJoin([from(Storage.get({key: 'JSONWebToken'})), from(Storage.get({key: 'User'}))]).pipe(map(([tokenObject, user]) => {
        if (tokenObject && tokenObject.value !== null && user && user.value !== null) {
          if (this.user.value === null) {
            //setter l'objet User et Token de l'utiliosateur pour gerer les valeurs null des entités de l'utilisateur.

            this.userDataAutoSetting(new User(this.storageService.decrypt(user.value, "User")), this.storageService.decrypt(tokenObject.value, "JSONWebToken"));

          }
          return true;
        } else {
          this.route.queryParams.subscribe(params => {


            this.router.navigateByUrl('/connexion');

          });

          return false;
        }

      })
    );
  }


  //logout a user
  async logout(path) {
    await this.disconnect().then(d => {
      Storage.remove({key: 'JSONWebToken'}).then(r => {
        Storage.remove({key: 'User'}).then(q => {
          this.onLoginEvent.next(null);
          this.user.next(null);
          this.jwt.next(null);
          this.router.navigateByUrl(path);
        });
      });
    });
  }


  //get user's informations from server
  fetchUserData(requestBodyResponse = null): Observable<User> {

    // if (!this.jwt.value) {
    //    //this.router.navigateByUrl('/connexion');
    //   // return;
    // }
    return this.http.get<User>(baseUrl + `api-webapp/user/jwt`).pipe(
      map(userData => {
        //Create user object from data
        const user = new User(userData);
        this.storeUser(user);

        this.user.next(new User(user));
        return user;
      })
    )
  }


  //get token from server
  login(username: string, password: string): Observable<Object> {
    return this.http.post(baseUrl + `api-jwt/login_check`, {username, password});
  }


  //store token in device storage
  storeToken(jwtToken: JSONWebToken) {
    //Expiration du token en MS
    const expiration_date = new Date(new Date().getTime() + (2592000 * 1000));

    //create JSONWebToken object
    const tokenData: JSONWebToken = {
      token: jwtToken.token,
      refresh_token: jwtToken.refresh_token,
      expiration_date
    }

    this.jwt.next(tokenData);

    this.storageService.set('JSONWebToken', tokenData);

    return true;
  }

  //store a user in device storage
  storeUser(user: User) {

    this.storageService.set('User', user);
    this.user.next(user);
    return true;
  }

  getToken(): Observable<JSONWebToken> {
    if (!Storage.get({key: 'JSONWebToken'})) {
      return null;
    } else {
      return from(Storage.get({key: 'JSONWebToken'})).pipe(map(storedData => {

          //if device stored token not found, return null
          if (!storedData || !storedData.value) {
            return null;
          }

          const jwt = JSON.parse(storedData.value) as JSONWebToken;

          //if token is expired
          if (jwt.expiration_date <= new Date()) {
            return null;
          }

          return jwt;

        }),
        tap(jwt => {
          if (jwt) {
            this.jwt.next(jwt);
          }
        }),
        map(jwt => {
          return jwt;
        })
      );
    }
  }


  async presentLoading(message: string) {

    this.loadingElement = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant(message),
    });
    await this.loadingElement.present();

  }

  getChatUser(user) {

    let data = {
      'name': user.id + user.organisation.identifier + user.organisation.id,
      'userId': user.id
    }


    this.stream.getUserToken(data).subscribe(async token => {

      if (user.streamToken == null) {
        user.streamToken = token;
      }

      this.connectedStreamClient = StreamChat.getInstance('5w6s5vqyg8pf', {
        timeout: 6000,
      });

      let streamUser = await this.connectedStreamClient.connectUser(
        {
          id: data['name'],
          name: user.firstName + ' ' + user.lastName,
          count: user.id
        },
        user.streamToken);

      let streamUserData = {
        'id': streamUser['me']['id'],
        'count': user.id
      }

      if (user?.isGranted("ROLE_LAWYER")) {
        streamUserData['assignation'] = 'lawyer'
        streamUserData['name'] = 'Maître ' + user.lastName;
        this.connectedStreamClient.upsertUser(streamUserData);
      } else if (user?.isGranted("ROLE_ASSISTANT")) {
        streamUserData['name'] = user.firstName + ' - Conseiller Maluma';
        streamUserData['assignation'] = 'assistant'
        this.connectedStreamClient.upsertUser(streamUserData);
      }

      //todo go back and see how to change so we can save the information
      this.stream.chatClient.next(this.connectedStreamClient);
      this.connectedStreamClient.on('notification.message_new', callback => {
      });

    });
  }

  async disconnect() {
    if (this.connectedStreamClient) {
      await this.connectedStreamClient.disconnectUser().then(() => {
        this.connectedStreamClient = null;
        this.stream.chatClient.next(null);
      });
    }
  }
}
