import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {
  Capacitor
} from "@capacitor/core";
import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications";
import {HttpClient} from "@angular/common/http";
import {baseUrl} from "../parameters";
import {take, tap} from "rxjs/operators";
import {MessagingService} from "../manager/messaging";


@Injectable({
  providedIn: 'root'
})

export class FcmService {
  constructor(private router: Router, private messagingService: MessagingService, private http: HttpClient) {
  }

  public initPush(data = null) {
    //only works on phone so we add get platform !== web so we wont get the error
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush(data);
    }
  }


  private registerPush(data) {
    PushNotifications.requestPermissions().then(permission => {
      if (permission.receive === "granted") {
        PushNotifications.register();
      }

    });


    PushNotifications.addListener('registration', (token: Token) => {
      if (data != null) {
        data['token'] = token.value;
        data['deviceType'] = "mobile";
      }

      this.messagingService.registerToken(data).subscribe();
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      // console.log('Error', JSON.stringify(error))
    });

    //notification was received
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      if (data.detailsId === 1) {
        this.router.navigateByUrl('/plan');
      } else {
        this.router.navigateByUrl('/consultations');
      }
    });

    //on click notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      const data = notification.notification.data;

      //action
      if (data.detailsId === 1) {
        this.router.navigateByUrl('/plan');
      } else {
        this.router.navigateByUrl('/consultations');
      }
    });
  }

  sendPush(data) {
    return this.http.post<any>(`https://fcm.googleapis.com/fcm/send`, data).subscribe(response => {
      // console.log('response', response);
    });
  }
}
