// Url local

// export const baseUrl = "http://127.0.0.1/maluma-symfony-backend/web/app_dev.php/";


// Url de "fake" production mais en dev
export const baseUrl = "https://api.maluma.ca/";


export const AMM_API_KEY = 'EG#*ayll&aY@xu0TXpcIUEac2g54RtHBuq@uWe@Q4eo694sV!BCk@V7Eu#ahxkfMj&iv!^kZDh67YqWiW@KSWrhpyfTUi&7ySID';
//export const AMM_API_KEY = 'dsafhyui9sfjisft90';

export const version = '1.07';
