import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable} from 'rxjs';
import {AuthentificationService} from "../manager/authentification.service";
import {User} from "../entities/User";
import {Storage} from "@capacitor/storage";
import {organisationService} from "../manager/organisation.service";
import {storageService} from "../manager/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthentificationService, private router: Router, private orgService: organisationService, private storageService: storageService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.storageService.get('User').subscribe(value => {
      if (value.value) {
        var user = value.value as User
        if (!user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_SUPER_ADMIN")) {
          this.authService.logout('/connexion');
        }

      }
    });
    return true;
  }

}
