import {Component, OnInit} from '@angular/core';
import {AuthentificationService} from "./manager/authentification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuController} from "@ionic/angular";
import {Form, NgForm} from "@angular/forms";
import {User} from "./entities/User";
import {NavigationService} from "../@vex/services/navigation.service";
import {NavigationItem, NavigationLink} from "../@vex/interfaces/navigation-item.interface";
import {NavigationComponent} from "../@vex/layout/navigation/navigation.component";
import dashboard from '@iconify/icons-ic/dashboard';
import icChat from        '@iconify/icons-ic/twotone-chat';
import icSettings from    '@iconify/icons-ic/twotone-settings';
import faCard from        '@iconify/icons-fa-solid/credit-card';
import faCheck from       '@iconify/icons-fa-solid/check-square';
import icLogout from      '@iconify/icons-ic/baseline-log-out';
import icExclamation from '@iconify/icons-fa-solid/exclamation-circle';
import icListAlt from "@iconify/icons-ic/twotone-list-alt";
import icPerson from "@iconify/icons-ic/twotone-person";
import icPersonAdd from "@iconify/icons-ic/twotone-person-add";
import icGift from "@iconify/icons-fa-solid/gift";
import {LayoutService} from "../@vex/services/layout.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  menuItems = []
  user: User
  isMobile;

  constructor(private navigationService: NavigationService, private authService: AuthentificationService,
              private router: Router, private menuCtrl: MenuController, private layoutService : LayoutService, private translate: TranslateService) {
     translate.setDefaultLang('fr');
  }

  ngOnInit(): void {

    this.layoutService.isMobile$.subscribe((val)=>{

      this.isMobile = val;

      this.authService.getUserAsObservable().subscribe(user => {

        this.menuItems = []

        const logoutObject = {
          label: 'Déconnexion',
          url: '',
          activated: false,
          type: 'link',
          icon: icLogout,
          index: 0
        }

        if (user !== null) {

          this.user = user

          //add users tab to menu is current user is admin
          let adminObject = {};
          let billingObject = {};
          let orgObject = {};
          let planObject = {};
          let linkObject = {};
          let paymentObject = {};
          let consultationObject = {};
          let couponObject = {};

          if (user?.isGranted("ROLE_ADMIN") && this.isMobile == false) {
            adminObject = {
              label: 'Utilisateurs',
              url: 'admin/users',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icPersonAdd
            };
            billingObject={
              label: 'Modifier l\'organisation',
              url: 'admin/organisation',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icPerson
            };

           if(user?.isGranted("ROLE_FINANCEMENT") || user?.isGranted("ROLE_ADMIN")){
              orgObject={
                label: 'Facturation',
                url: 'admin/billing',
                activated: window.location.href.includes('user'),
                type: 'link',
                icon: faCard
              };
            }
          }
          else if(user?.isGranted("ROLE_SUPER_ADMIN")){
            adminObject = {
              label: 'Utilisateurs',
              url: 'admin/users',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icListAlt
            };
            consultationObject = {
              label: 'Mes consultations',
              url: '/consultations',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icChat
            };

            couponObject = {
              label: 'Coupons',
              url: '/admin/coupons',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icGift
            };


          }
          else if(user?.isGranted("ROLE_ASSISTANT") || user?.isGranted("ROLE_LAWYER")){
            consultationObject = {
              label: 'Mes consultations',
              url: '/consultations',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icChat
            };
          }
          else {
            consultationObject = {
              label: 'Mes consultations',
              url: '/consultations',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icChat
            };
            planObject = {
              label: 'Mon forfait',
              url: 'user/plan',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: faCheck
            };
            linkObject = {
              label: 'Liens rapides',
              url: 'user/quick-links',
              activated: window.location.href.includes('user'),
              type: 'link',
              icon: icExclamation
            };
          }

          if (user?.isGranted("ROLE_ADMIN") && this.isMobile == false) {
            this.menuItems = [adminObject, billingObject, orgObject];
          }
          else if(user?.isGranted("ROLE_SUPER_ADMIN")){
            this.menuItems = [consultationObject, adminObject,couponObject ];
          }
          else if(user?.isMalumaUser()){
            this.menuItems = [consultationObject];
          }
          else {
            this.menuItems = [consultationObject,planObject,linkObject];
          }

          // Insérer les items dans le service de navigation de Vex
          this.navigationService.items = [
            {
              type: 'subheading',
              label: '',
              children: [
                // Items en haut

                ...this.menuItems
              ]
            },
            {
              type: 'subheading',
              label: '',
              children: [
                // Items en bas
                // logoutObject
              ]
            }
          ];

        }

      });

    });
  }

  handler(index) {
    this.closeMenu()

    //si l'item cliquer est deconnexion
    if (index === (this.menuItems.length - 1)) {
      this.logoutClick();

      //set the first item activated
      this.menuItems.forEach((item, itemIndex) => {
        item.activated = itemIndex === 0;
      });

    } else {

      //desactiver toute les items
      this.menuItems.forEach(item => {
        item.activated = false;
      });

      this.menuItems[index].activated = true;

    }
  }


  logoutClick() {
    this.authService.logout('/connexion');
    this.menuItems.forEach(item => {
      item.activated = false;
    });
    this.closeMenu();
  }

  closeMenu() {
    this.menuCtrl.close();
  }

}
