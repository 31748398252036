import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {baseUrl} from "../parameters";
import {take, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {Message, StreamChat} from "stream-chat";

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  jwt = new BehaviorSubject<any>(null);
  chatClient = new BehaviorSubject<StreamChat>(null);
  createChannelTrigger = new BehaviorSubject<boolean>(null);
  messageTrigger = new BehaviorSubject<Array<Message>>(null);

  constructor(private http: HttpClient) {
  }

  //create new user
  getUserToken(data) {
    return this.http.post<any>(baseUrl + `api-webapp/stream/userJwtToken`, data).pipe(
      take(1),
      tap(response =>{}));
  }

  modifyChannel(data) {
    return this.http.post<any>(baseUrl + `api-webapp/stream/modifyChannel`, data).pipe(
      take(1),
      tap(response =>{})
    );
  }

  updateUser(data) {
    return this.http.post<any>(baseUrl + `api-webapp/stream/modifyUser`, data).pipe(
      take(1),
      tap(response =>{})
    );
  }

  assistantsOnline(data) {
    return this.http.post<any>(baseUrl + `api-webapp/stream/getAssistants`,data).pipe(
      take(1),
      tap(response =>{
      })
    );
  }


}
