import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {User} from "../entities/User";
import {baseUrl} from "../parameters";
import {take, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userList = new BehaviorSubject<User[]>([])

  constructor(private http: HttpClient) {
  }

  //create new user
  create(dataObject) {
    return this.http.post<any>(baseUrl + `api/user/create`, JSON.stringify(dataObject)
    ).pipe(
      take(1),
      tap())
  }

  //create new user
  addUser(dataObject) {
    return this.http.post<any>(baseUrl + `api-webapp/user/add`, JSON.stringify(dataObject)
    ).pipe(
      take(1),
      tap())
  }

  addMultipleUsers(dataObject) {
    return this.http.post<any>(baseUrl + `api-webapp/user/add-multiple`, JSON.stringify(dataObject)
    ).pipe(
      take(1),
      tap())
  }

  getUserListAsObservable() {
    return this.userList.asObservable()
  }

  //get all users from server
  getUserList(orgId) {
    return this.http.get<User[]>(baseUrl + `api-webapp/user/${orgId}`
    ).pipe(
      take(1),
      tap(userList => {

        let userTempArray = []
        if (userList.length > 0) {
          userList.forEach(user => {
            userTempArray.push(new User(user))
          })
          this.userList.next(userTempArray)
        }
      }))
  }

  getLicenseCount(orgId) {
    return this.http.get<number>(baseUrl + `api-webapp/user/${orgId}/billing`)
  }


  getUserById(orgId, userId) {
    return this.http.get<User>(baseUrl + `api-webapp/user/${orgId}/${userId}`
    ).pipe(
      take(1),
      tap())
  }

  updateUser(data: object, userId) {
    return this.http.put<any>(baseUrl + `api-webapp/user/modify/${userId}`, JSON.stringify(data)
    ).pipe(
      take(1),
      tap())
  }

  // enable or disbale a user account according to account status
  disableUserAccount(userId) {
    return this.http.put<any>(baseUrl + `api-webapp/user/active/${userId}`, {})
      .pipe(
        take(1),
        tap()
      )
  }

  //send a password reset request to server
  sendResetRequest(data: object) {
    return this.http.post<any>(baseUrl + `api/user/sendResetRequest`, JSON.stringify(data))
      .pipe(
        take(1),
        tap()
      )
  }

  //reset account password
  resetPassword(data: object) {
    return this.http.put<any>(baseUrl + `api-webapp/user/reset`, JSON.stringify(data))
      .pipe(
        take(1),
        tap()
      )
  }

  //using a generated token server side to reset user password
  resetPasswordByToken(data: object) {
    return this.http.post<any>(baseUrl + `api/user/changePassword`, JSON.stringify(data))
      .pipe(
        take(1),
        tap()
      )
  }

  //finalize user subscription
  finalizeUser(data: object) {
    return this.http.post<any>(baseUrl + `api/user/finalize`, JSON.stringify(data))
      .pipe(
        take(1),
        tap()
      )
  }

}
