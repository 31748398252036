import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {baseUrl} from "../parameters";
import {take, tap} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {BillingContact} from "../entities/BillingContact";
import {Organisation} from "../entities/Organisation";

@Injectable({
  providedIn: 'root'
})
export class organisationService {

  constructor(private http: HttpClient) {
  }

  organisationTrigger = new BehaviorSubject<boolean>(null);

  //create new user
  create(dataObject) {
    return this.http.post<any>(baseUrl + `api/organisation/create`, JSON.stringify(dataObject))
  }

  modify(data, dataObject) {
    return this.http.put<any>(baseUrl + `api-webapp/organisation/modify/` + data, JSON.stringify(dataObject))
  }

  exist(data) {
    return this.http.get<any>(baseUrl + `api-webapp/organisation/` + data,
    ).pipe(
      take(1),
      tap())
  }

  createBillingContact(dataObject) {
    return this.http.post<any>(baseUrl + `api-webapp/organisation/contact`, JSON.stringify(dataObject)
    ).pipe(
      take(1),
      tap())
  }

  getList(){
    return this.http.get<any>(baseUrl + `api-webapp/organisation/getList`)
  }

  modifyPayment(data, dataObject) {
    return this.http.post<any>(baseUrl + `api-webapp/organisation/payment-method/` + data, JSON.stringify(dataObject))
  }
}
