import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {User} from "../../../../../app/entities/User";
import {AuthentificationService} from "../../../../../app/manager/authentification.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {untilDestroyed} from "@ngneat/until-destroy";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit, OnDestroy{

  user : User;
  activeStatus: OnlineStatus;
  items: MenuItem[];
  statuses: OnlineStatus[];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  subscription: any;

  constructor(
              private translate : TranslateService,
              private authService: AuthentificationService,
              private router: Router,
              private activatedroute: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { }

  ngOnInit() {

    this.subscription = this.authService.user.subscribe((user) => {

      if (user != null){

        this.user = user;

        this.items = [
          {
            id: '1',
            icon: icAccountCircle,
            label: 'Profil',
            description: this.translate.instant('configurez-profil'),
            colorClass: 'text-teal',
            route: 'user/profil'
          }
        ];

        if (user?.isMalumaUser()){
          this.items.splice(0,1);
        }

        this.statuses = [
          {
            id: 'online',
            label: 'Online',
            icon: icCheckCircle,
            colorClass: 'text-green'
          },
          {
            id: 'away',
            label: 'Away',
            icon: icAccessTime,
            colorClass: 'text-orange'
          },
          {
            id: 'dnd',
            label: 'Do not disturb',
            icon: icDoNotDisturb,
            colorClass: 'text-red'
          },
          {
            id: 'offline',
            label: 'Offline',
            icon: icOfflineBolt,
            colorClass: 'text-gray'
          }
        ];

        this.activeStatus = this.statuses[0];

      }
    });

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  closeAndLogout(){
    this.popoverRef.close();
    this.authService.logout('/connexion');
  }

}
