import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, map, take, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Storage} from "@capacitor/storage";
import {JSONWebToken} from "../entities/JSONWebToken";
import {User} from "../entities/User";
import {VersionControlService} from "../manager/version-control.service";
import {AuthentificationService} from "../manager/authentification.service";
import {AMM_API_KEY} from "../parameters";
import {version} from "../parameters";
import {Router} from "@angular/router";
import {AlertController} from "@ionic/angular";
import {LayoutService} from "../../@vex/services/layout.service";
import {storageService} from "../manager/storage.service";

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {

  private user: User;

  constructor(private vcs: VersionControlService,
              private authService: AuthentificationService,
              private alertController: AlertController,
              private router: Router,
              private layoutService: LayoutService,
              private storageService: storageService,) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenObjectValue: JSONWebToken = this.authService.jwt.value
    let token = null
    if (this.authService.jwt.value !== null) {
      token = tokenObjectValue.token
      //check if the expiration date of the jwt exist and if so, look at it's data
      if (this.authService.jwt.value.expiration_date !== undefined) {
        //convert token value to a date similar to new Date to compare
        const tokenExpirationDate = this.authService.jwt.value.expiration_date.toString();

        const date = tokenExpirationDate.split('T');
        const time = date[1].split('.');
        const tokenDate = new Date(date[0] + ' ' + time[0]);
        //if the date of the jwt token is expired we logout the user

        if (tokenDate < new Date()) {
          this.authService.logout('/connexion');
        }
      }
    }
    //we get the device version in the storage
    Storage.get({key: 'deviceVersion'}).then(value => {
      if (value.value === null) {
        Storage.set({key: 'deviceVersion', value: version});
        return;
      }

      //if the version of the device (value) is different from the app version (version) we logout the user
      if (value.value !== version) {
        Storage.remove({key: 'JSONWebToken'});
        Storage.remove({key: 'User'});
        this.router.navigate(['/connexion/'], {queryParams: {v: version}});
      }

    });

    let changedReq = req
    let header = null

    // add necessary informations in each request header
    if (req.url.includes('login_check')) {
      // add these informations to header when user is loging in or creating a new account
      header = {'Content-Type': 'application/json', 'ApiKey': AMM_API_KEY}
    } else if (req.url.includes('api/user/fileTest')) {
      header = {'ApiKey': AMM_API_KEY}
    } else if (req.url.includes('fcm.googleapis')) {
      header = {'Authorization': 'key=AAAABoqIONA:APA91bFJhdiXUjBV1AxPaIxmEVMG2q3mgtBY9vjx8nwsB9m5p8cxFaP2KlwQFyzGxUMd8p6S0TS75m1bXnGZmF6Q_KpPY5xSz79Nwtib9Dcl5VExHZGwd674XC0VgfBKA9dTd1G4e72C'}
    } else {
      //add these informations to header when user is making any request different from login_check and register
      header = {'Content-Type': 'application/json', 'ApiKey': AMM_API_KEY, Authorization: `Bearer ${token}`}
    }


    changedReq = req.clone({
      setHeaders: header
    });
    return next.handle(changedReq).pipe(
      map((event: HttpEvent<any>) => {
        //is called if the event is of type HttpResponse
        if (event instanceof HttpResponse) {


          //we look at the version of the backend sent from the headers compared to our version
          // if (event.headers.get('webapp-version') !== null) {
          //   this.vcs.checkVersion(event.headers.get('webapp-version'));
          // }

          Storage.get({key: 'User'}).then(value => {
            if (value.value) {

              let user = this.storageService.decrypt(value.value, "User") as User;

              this.user = new User(user);
              if (user.roles === undefined) {
                this.authService.fetchUserData();
              }

              //che user role from server with the user role in device

              let roles = event.headers.get('web-app-user-role');
              if (roles !== null) {
                if (this.authService.user.value !== null) {
                  if ((this.authService.user.value.roles.includes(roles) === false && this.authService.user.value.roles.length !== 0) || (this.authService.user.value.roles.length === 0 && roles !== "ROLE_USER")) {
                    user.roles = [];
                    user.roles.push(roles);
                    Storage.set({key: 'User', value: JSON.stringify(user)});
                    this.authService.user.next(new User(user));
                    //if role is changed in the backend, change his roles and redirect user to dashboard
                    window.location.reload();
                    //  this.router.navigateByUrl('/dashboard')
                  }
                }
              }
            } else {
              this.authService.fetchUserData(event.body);
            }

            let subEnd = event.headers.get('subscription_end');
            if (subEnd !== null) {
              if (this.user !== null) {
                if (this.user?.isGranted('ROLE_ADMIN') && this.user.organisation != null && subEnd === '') {
                  this.router.navigateByUrl("/subscription-error");
                } else if (this.user?.isUser() && subEnd === '') {
                  this.authService.logout("/subscription-error");
                }
              }
            }
          });
        }
        return event;
      }), catchError((error: HttpErrorResponse) => {
        const data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };

        //kick the user out if be back-end send
        // if (data.status == 401) {
        //   this.authService.logout()
        //   window.location.reload()
        // }
        return throwError(error);
      }));

  }


}
