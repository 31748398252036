import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthentificationGuard} from "./guard/authentification.guard";
import {AdminGuard} from "./guard/admin.guard";
import {UserGuard} from "./guard/user.guard";
import {CustomLayoutComponent} from "./custom-layout/custom-layout.component";
import {FinalizeGuard} from "./guard/finalize.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'connexion',
    pathMatch: 'full'
  },
  {
    path: 'download-link',
    loadChildren: () => import('./pages/download-link/download-link.module').then(m => m.DownloadLinkPageModule)
  },
  {
    path: 'subscription-error',
    loadChildren: () => import('./pages/subscription-error/subscription-error.module').then(m => m.SubscriptionErrorPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/create-user/create-user.module').then(m => m.CreateUserPageModule)
  },
  {
    path: 'connexion',
    loadChildren: () => import('./pages/connexion/connexion.module').then(m => m.ConnexionPageModule)
  },

  {
    path: 'password-forgot',
    loadChildren: () => import('./pages/password-forgot/password-forgot.module').then(m => m.PasswordForgotPageModule)
  },
  {
    path: 'token-password/:token',
    loadChildren: () => import('./pages/token-password/token-password.module').then(m => m.TokenPasswordPageModule)
  },
  {
    path: 'finalize/:token',
    loadChildren: () => import('./pages/complete-user/complete-user.module').then(m => m.CompleteUserPageModule)
  },
  {
    path: 'components',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
  },
  {
    path: 'register-organisation',
    loadChildren: () => import('./pages/create-organisation/create-organisation.module').then(m => m.CreateOrganisationPageModule),
    canActivate: [AuthentificationGuard]
  },
  {
    path: 'consultations',
    component: CustomLayoutComponent,
    canActivate: [AuthentificationGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/user/consultations/consultations.module').then(m => m.ConsultationsPageModule)
      }
    ]
  },
  {
    path: 'user',
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule),
        canActivate: [UserGuard, AuthentificationGuard]
      }]
  },
  {
    path: 'admin',
    component: CustomLayoutComponent,
    children:[
      {
        path: '',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
        canActivate: [AdminGuard, AuthentificationGuard]
      }
    ]
  },
  {
    path: 'create-organisation',
    loadChildren: () => import('./pages/create-organisation/create-organisation.module').then( m => m.CreateOrganisationPageModule)
  },
  {
    path: 'subscription-error',
    loadChildren: () => import('./pages/subscription-error/subscription-error.module').then( m => m.SubscriptionErrorPageModule)
  },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full',
    redirectTo: 'connexion',
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
