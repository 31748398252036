import {Organisation} from "./Organisation";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: Array<string>;
  enable: boolean;
  finalized: boolean;
  organisation: Organisation;
  province: string;
  cellphone: string;
  homephone: string;
  dateOfBirth: Array<string>;
  language: string;
  sex: string;
  address: string;
  city: string;
  postalCode: string;
  streamToken: string;
  stripeUserToken: string;
  devices: Array<string>;

  constructor(user: User = null) {

    if (user) {
      this.id = user.id;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.roles = user.roles
      this.enable = user.enable
      this.finalized = user.finalized;
      this.organisation = user.organisation;
      this.province = user.province;
      this.cellphone = user.cellphone;
      this.homephone = user.homephone;
      this.dateOfBirth = user.dateOfBirth;
      this.language = user.language;
      this.sex = user.sex;
      this.address = user.address;
      this.city = user.city;
      this.postalCode = user.postalCode;
      this.devices = user.devices;
    }
  }

  isGranted(roleArgs: string) {
    return !!this.roles.find(role => role == roleArgs)
  }

  isUser() {
    return !this.isGranted('ROLE_ADMIN') &&
           !this.isGranted('ROLE_SUPER_ADMIN') &&
           !this.isGranted('ROLE_LAWYER') &&
           !this.isGranted('ROLE_ASSISTANT')
  }

  isMalumaUser() {
    return this.isGranted('ROLE_SUPER_ADMIN') || this.isGranted('ROLE_LAWYER') || this.isGranted('ROLE_ASSISTANT')
  }


}
