import { Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import {NavigationItem} from '../../interfaces/navigation-item.interface';
import {AuthentificationService} from "../../../app/manager/authentification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean = false;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));

  items = this.navigationService.items;

  trackByRoute = trackByRoute;

  user = null;

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private authentificationService : AuthentificationService,
              private router : Router) { }

  ngOnInit() {
    this.authentificationService.user.subscribe((user)=>{
      if (user != null){
        this.user = user;
      }
    });
  }


  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  goHome() {
    if (this.user?.isGranted('ROLE_ADMIN')){
      this.router.navigateByUrl('admin/billing');
    } else {
      this.router.navigateByUrl('/consultations');
    }
  }
}
