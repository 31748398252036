import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthentificationService} from "../manager/authentification.service";

@Injectable({
  providedIn: 'root'
})

//use to guard resources pages
export class AuthentificationGuard implements CanActivate {

  constructor(private authService: AuthentificationService,
              private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //autologin a user anytime he want to enter a new

    return this.authService.autoLogin();
  }

}
