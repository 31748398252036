import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';
import {GetOptions, GetResult} from "@capacitor/storage/dist/esm/definitions";
import {Storage} from "@capacitor/storage";
import {User} from "../entities/User";
import {from} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class storageService {

  constructor() {
  }

  get(key: string): any {
    return from(Storage.get({key: key})).pipe(tap(data => {
      if (data.value)
        data.value = this.decrypt(data.value, key);
    }))
  }

  set(key: string, data: any): any {
    return from(Storage.set({key: key, value: this.encrypt(data, key)}));
  }


  encrypt(data, key) {
    return JSON.stringify(CryptoJS.AES.encrypt(JSON.stringify(data), key).toString());
  }

  decrypt(data, key) {
    let bytes = CryptoJS.AES.decrypt(JSON.parse(data), key);

    if(bytes['sigBytes'] === 0){
      Storage.remove({key: 'JSONWebToken'});
      Storage.remove({key: 'User'});
      window.location.reload();
    }

    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
