import {Injectable} from '@angular/core';
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {take, tap} from "rxjs/operators";
import {AuthentificationService} from "./authentification.service";
import {User} from "../entities/User";
import {UserService} from "./user.service";
import {baseUrl} from "../parameters";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null;

  constructor(private afMessaging: AngularFireMessaging, private userService: UserService,private http: HttpClient) {}

  requestPermission() {
    return this.afMessaging.requestToken;
  }

  getMessages() {
    return this.afMessaging.messages;
  }

  deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      //also delete in database
      this.token = null;
    }
  }

  registerToken(data) {
    return this.http.post<any>(baseUrl + `api-webapp/device/register`, data);
  }

  sendPushNewConsultation(data) {
    return this.http.post<any>(baseUrl + `api-webapp/device/sendpush`, data);
  }


}
