import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input, OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {LayoutService} from '../services/layout.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet, Scroll} from '@angular/router';
import {filter, map, startWith, take, tap, withLatestFrom} from 'rxjs/operators';
import {checkRouterChildsData} from '../utils/check-router-childs-data';
import {DOCUMENT} from '@angular/common';
import {ConfigService} from '../services/config.service';
import {AuthentificationService} from "../../app/manager/authentification.service";
import {Subscription} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() sidenavRef: TemplateRef<any>;
  @Input() toolbarRef: TemplateRef<any>;
  @Input() footerRef: TemplateRef<any>;
  @Input() quickpanelRef: TemplateRef<any>;

  isLayoutVertical$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isBoxed$ = this.configService.config$.pipe(map(config => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(map(config => config.toolbar.fixed));
  isFooterFixed$ = this.configService.config$.pipe(map(config => config.footer.fixed));
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;
  isDesktopSize$ = this.layoutService.isDesktopSize$;
  isIos = this.layoutService.isIos();

  user = null;

  userDontNeedSidebar = false;

  scrollDisabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  containerEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.containerEnabled))
  );

  searchOpen$ = this.layoutService.searchOpen$;

  subscriptions : any = [];

  @ViewChild('quickpanel', {static: true}) quickpanel: MatSidenav;
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;
  @ViewChild('sidenavContent', {static: true}) sidenavContent: MatSidenavContent;
  @ViewChild(MatSidenavContainer, {static: true}) sidenavContainer: MatSidenavContainer;

  constructor(private cd: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              private authService: AuthentificationService) {
  }

  ngOnInit() {

    this.subscriptions += this.authService.user.subscribe((user) => {
        if (user != null) {
          this.user = user;
        }
        if (
          this.user?.isGranted('ROLE_LAWYER')                      // If assistant
          || this.user?.isGranted('ROLE_ASSISTANT')                // if lawyer
          || (!this.user?.isMalumaUser() && this.user.finalized == null)   // If random user and not finalized
        ) {
          this.userDontNeedSidebar = true;
          this.sidenavRef = null;
        }
      }
    );

    this.subscriptions += this.isDesktop$.pipe(
      filter(matches => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.layoutService.expandSidenav());

    /**
     * Expand Sidenav when we switch from mobile to desktop view
     */

    /**
     * Open/Close Quickpanel through LayoutService
     */
    this.subscriptions += this.layoutService.quickpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.quickpanel.open() : this.quickpanel.close());

    /**
     * Open/Close Sidenav through LayoutService
     */
    this.subscriptions += this.layoutService.sidenavOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.sidenav?.open() : this.sidenav?.close());

    /**
     * Mobile only:
     * Close Sidenav after Navigating somewhere (e.g. when a user clicks a link in the Sidenav)
     */
    this.subscriptions += this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      withLatestFrom(this.isDesktop$),
      filter(([event, matches]) => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.sidenav?.close());
  }

  ngOnDestroy() {
    for (let i = 0 ; i < this.subscriptions.length; i++){
      if (this.subscriptions[i] instanceof Subscription){
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  ngAfterViewInit(): void {

    /**
     * Enable Scrolling to specific parts of the page using the Router
     */
    this.subscriptions += this.router.events.pipe(
      filter<Event, Scroll>((e: Event): e is Scroll => e instanceof Scroll),
      untilDestroyed(this)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.sidenavContainer.scrollable.scrollTo({
          start: e.position[0],
          top: e.position[1]
        });
      } else if (e.anchor) {
        // anchor navigation

        const scroll = (anchor: HTMLElement) => this.sidenavContainer.scrollable.scrollTo({
          behavior: 'smooth',
          top: anchor.offsetTop,
          left: anchor.offsetLeft
        });

        let anchorElem = this.document.getElementById(e.anchor);

        if (anchorElem) {
          scroll(anchorElem);
        } else {
          setTimeout(() => {
            anchorElem = this.document.getElementById(e.anchor);
            scroll(anchorElem);
          }, 100);
        }
      } else {
        // forward navigation
        this.sidenavContainer.scrollable.scrollTo({
          top: 0,
          start: 0
        });
      }
    });
  }
}
