import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthentificationService} from "./authentification.service";
import {Router} from "@angular/router";
import {version} from "../parameters";
import {Storage} from "@capacitor/storage";

@Injectable({
  providedIn: 'root'
})
export class VersionControlService {

  constructor(private http: HttpClient, private authService: AuthentificationService, private router: Router) {
  }

  checkVersion(backendVersion: string): boolean {

    //check backend version with device current version
    if (backendVersion !== version) {
      Storage.get({key: 'versionAccepter'}).then(value => {
        if (value.value !== backendVersion) {
          this.router.navigate(['connexion/version-control'], {queryParams: {v: backendVersion}});
        }
      });
    }


    return true;
  }
}
